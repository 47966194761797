//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import NotificationViewToolbar from '@/modules/notification/components/notification-view-toolbar.vue';
import { NotificationModel } from '@/modules/notification/notification-model';

const { fields } = NotificationModel;

export default {
  name: 'app-notification-view-page',

  props: ['id'],

  components: {
    [NotificationViewToolbar.name]: NotificationViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'notification/view/record',
      loading: 'notification/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'notification/view/doFind',
    }),

    presenter(record, fieldName) {
      return NotificationModel.presenter(record, fieldName);
    },
  },
};
